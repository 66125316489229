import React from 'react';

function Favorites(){

    return (
        <div className='myAccount-container'>
            <h1>Favorite Movies</h1>
            
        </div>
    );
}

export default Favorites;